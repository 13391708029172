$faint-text-opacity: 0.75;

/** 
  * Sizes 
  */
$size-xxx-small: 4px;
$size-xx-small: 8px;
$size-x-small: 12px;
$size-small: 16px;
$size-medium: 24px;
$size-large: 32px;
$size-x-large: 48px;
$size-xx-large: 56px;
$size-xxx-large: 64px;

/** 
  * Spacing 
  */
$spacing-xxx-small: 4px;
$spacing-xx-small: 8px;
$spacing-x-small: 12px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-x-large: 48px;
$spacing-xx-large: 56px;
$spacing-xxx-large: 64px;
$spacing-xxxx-large: 72px;
$spacing-xxxxx-large: 90px;