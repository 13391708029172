@import 'src/variables.scss';
@import 'src/colors.scss';

.settlement-calculator {
  min-width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: $size-small;
  color: $text-color;

  background: $background-color-base-1;
  background: linear-gradient(90deg, $background-color-base-1 50%, $background-color-accent 100%);
  background: -moz-linear-gradient(90deg, $background-color-base-1 50%, $background-color-accent 100%);
  background: -webkit-linear-gradient(90deg, $background-color-base-1 50%, $background-color-accent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$background-color-base-1", endColorstr="$background-color-accent", GradientType=1 );

  &-header {
    margin-top: $spacing-medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  &-buttons-container {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
    gap: $spacing-medium;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-person {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: $size-small;
    align-items: center;
    border: 1px lightgray;

    &-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: $spacing-xx-small;
    }

    &-delete-button {
      :hover {
        fill: red;
      }
    }

    &-edit-button {
      :hover {
        fill: #1976d2;
      }
    }
  }

  &-debt-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    justify-content: center;
  }

  &-debt {
    text-align: center;
  }
}

.modal-person-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-medium;

  &-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $spacing-xxx-small;
  }
}
