@import 'src/variables.scss';

.contact {
    &-title-container {
        margin-bottom: $spacing-x-small;
    }

    .contact-detail-text {
        padding-left: $spacing-medium;
    }
}