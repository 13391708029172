@import 'src/variables.scss';

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24;
    padding: 32px;
    display: flex;
    flex-direction: column;

    &-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: $spacing-small;
        padding-top: $spacing-medium;
    }
  }