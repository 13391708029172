@import 'src/variables.scss';

.travel-log {
    height: 75vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-large;
}

.travel-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    padding-top: $spacing-medium;
}

.travel-map {
    height: 1080px;
    width: 1200px;
}

.popup {
    margin-bottom: 55px;
    margin-left: 1px;
}
