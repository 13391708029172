@import './variables.scss';
@import './colors.scss';

.MuiTypography-root {
    font-family: inherit !important;
}

.MuiAppBar-root {
    color: inherit !important;
    // Need to fix this later
    background-color: $background-color-base-1 !important;
    box-shadow: inherit !important;
}

.MuiPaper-root {
    left: 0 !important;
}