@import 'src/variables.scss';
@import 'src/colors.scss';

/**
 * App header background color is being overriden in the material overrides master file
 */

.header{
    padding: 0 $size-large 0 $size-large;
    height: $size-xxx-large;
    overflow-x: overlay;

    color: $text-color;
    a { color: var(--link-color); }

    box-shadow: none;

    &-nav-container{
        padding-left: $spacing-x-small;

        .header-nav-option {
            margin: 0 $size-x-small 0 $size-x-small;
            cursor: pointer;
        }

        :last-child {
            margin-right: 0;
        }
    }
}
