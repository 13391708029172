@import 'src/variables.scss';

.education {
    &-title-container {
        margin-bottom: $spacing-xx-large;
    }

    &-card{
        text-align: left;
        padding: $spacing-x-large;

        &-title-container {            
            align-items: baseline;
            padding-bottom: $spacing-x-small;

            > * { 
                padding-right: $spacing-xx-small;
            }
    
            :last-child {
                padding-right: 0;
            }
        }

        &-body-text{
            padding-bottom: $spacing-xx-small;

            > ul {
                margin: $spacing-xx-small 0 0 0;
            }
        }
    }
}